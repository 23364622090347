import React from 'react';
import {IconContext} from "react-icons";
import * as FaIcons from 'react-icons/fa';
import * as ImIcons from 'react-icons/im';
import * as AiIcons from 'react-icons/ai'; 

import classes from './Footer.module.css';
import globals from '@utils/GlobalWrapper/GlobalWrapper.module.css';

const Footer = () => {
    
    return (
        <React.Fragment>
            <footer className={[globals.Margin, classes.Footer, ""].join(" ")}>
                <div className={["container-fluid", classes.Container].join(" ")}>
                    <div className={["row", ""].join(" ")}>
                        <div className={["col col-12 col-md-6 col-xl-6", classes.ColLeft].join(" ")}>
                            <div className={["row", classes.Row].join(" ")}>
                                <div className={["col col-6 col-md-6 col-lg-5", classes.Col].join(" ")}>
                                    <IconContext.Provider value={{ size: '23px' }}>
                                        <span className={[classes.TextBox]}>
                                            <FaIcons.FaPhoneAlt className={classes.Icon}/> 
                                            <span className={classes.Text}>6329 1282</span>
                                        </span>
                                    </IconContext.Provider>
                                </div>
                                <div className={["col col-6 col-md-6 col-lg-5", classes.Col].join(" ")}>
                                    <IconContext.Provider value={{ size: '27px' }} >
                                    <span className={[classes.TextBox]}>
                                        <FaIcons.FaWhatsapp className={classes.Icon}/> 
                                        <span className={classes.Text}>6329 1282</span>
                                    </span>
                                    </IconContext.Provider>
                                </div>
                            </div>
                            <div className={["row", classes.Row].join(" ")}>
                                <div className={["col col-12", classes.Col].join(" ")}>
                                    <IconContext.Provider value={{ size: '27px' }} >
                                        <span className={[classes.TextBox]}>
                                            <AiIcons.AiOutlineMail className={classes.Icon}/> 
                                            <span className={classes.Text}>contact@pscholars.com 
                                            {/* <span className={classes.BracketText}>(一般查詢)</span> */}
                                            </span>
                                        </span>
                                    </IconContext.Provider>
                                </div>
                            </div>
                            {/* <div className={["row", classes.Row].join(" ")}>
                                <div className={["col col-12", classes.Col].join(" ")}>
                                    <IconContext.Provider value={{ size: '27px' }} >
                                        <span className={[classes.TextBox]}>
                                            <AiIcons.AiOutlineMail className={classes.Icon}/> 
                                            <span className={classes.Text}>cs@pscholars.com <span className={classes.BracketText}>(學校專用)</span></span>
                                        </span>
                                    </IconContext.Provider>
                                </div>
                            </div> */}
                            <div className={["row", classes.Row].join(" ")}>
                                <div className={["col col-12", classes.Col].join(" ")}>
                                    <IconContext.Provider value={{ color: 'white', size: '25px' }}>
                                        <span className={[classes.TextBox]}>
                                            <ImIcons.ImLocation className={classes.Icon}/> 
                                            <span className={classes.Text}>九龍佐敦柯士甸道102號6樓604-5室 <span className={classes.BracketText}>(近佐敦港鐵站D出口)</span></span>
                                        </span>
                                    </IconContext.Provider>
                                </div>
                            </div>
                            <div className={["row", classes.Row].join(" ")}>
                                <hr className={classes.HR}/>
                            </div>
                        </div>
                        <div className={["col col-12 col-md-6 col-xl-6", classes.ColRight].join(" ")}>
                            <div className={["row", classes.Row].join(" ")}>
                                <p className={[classes.PText]}>PREMIER SCHOLARS</p>
                                <p className={[classes.PText]}>A BRAND OF MUSE EDUCATION GROUP LIMITED</p>
                            </div>
                            <div className={["row", classes.Row].join(" ")}>
                                <p className={[classes.PText]}>COPYRIGHT © {new Date().getFullYear()}</p>
                                <p className={[classes.PText]}>PREMIER SCHOLARS EDUCATION CENTRE.</p>
                                <p className={[classes.PText]}>ALL RIGHTS RESERVED.</p>
                            </div>
                            <div className={["row", classes.Row].join(" ")}>
                                <p className={[classes.PText]}>版權所有 © {new Date().getFullYear()} 翰林薈教育中心</p>
                            </div>
                            <div className={["row", classes.Row].join(" ")}>
                                <p className={[classes.PText]}>免責聲明 LEGAL DISCLAIMER | 私隱政策 PRIVACY POLICY</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;